//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleListTable from '@/modules/cycle/components/cycle-list-table.vue';
import { mapGetters } from 'vuex'
export default {
    data(){
      return {
        search: ''
      }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
            // return this.$t(key, args);
        },
    },
    components: {
      [CycleListTable.name]: CycleListTable,
    },
    computed:{
      ...mapGetters({
        is_screen_xs: 'layout/is_screen_xs'
      })
    }
}
