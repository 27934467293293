//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
  name: 'app-cycle-list-table',

  data(){
      return {
            loading: false,
            columns: [
                {
                    name: 'tripDate',
                    required: true,
                    label: 'Trip Date',
                    align: 'center',
                    field: row => row.name,
                    format: val => `${val}`,
                },
                { name: 'tripName', align: 'center', label: 'Trip Name', field: 'tripName', },
                { name: 'branch', align: 'center', label: 'Branch', field: 'branch', },
                { name: 'totalMoney', align: 'center', label: 'Total Money', field: 'totalMoney' },
                { name: 'saryahFees', align: 'center', label: 'Saryah Fees', field: 'saryahFees' },
                { name: 'vat', align: 'center', label: 'VAT', field: 'sodium' },             
            ],
            // rows:[]
            rows: [
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                   tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                },
                {
                    tripDate: '2021-02-08T20:59:08.103Z',
                    tripName: 'Makkah trip',
                    branch: 'Makkah',
                    totalMoney: 1200,
                    saryahFees: 150,
                    vat: 150
                }
                ],
            pagination: {
                page: 1,
                rowsPerPage: 10,
            },


      }
  },

    computed:{
        ...mapGetters({
            currentLanguageCode: 'layout/currentLanguageCode',
        })
    },
  methods:{
    displayDate(value){
        return moment(value).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    i18n(key, args) {
        return this.$t(key, args);
    },
  }

}
